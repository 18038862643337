
	<template>
  <div class="total padding-lr-lg">
    <div class="work-title bg-white">
      <div
        class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
      >
        <div
          class="work-title-content flex aligin-items-center flex-wrap-wrap"
          style="width: 60%"
        >
          <el-popover width="150" trigger="click">
            <el-checkbox-group @change="school_change" v-model="school_name">
              <el-checkbox
                class="padding-xs"
                :label="item.name"
                v-for="(item, index) of school_list"
                :key="index"
              ></el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <span>校区</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>

          <div v-if="!query.school_id.length" slot="reference">
            <i class="el-icon-link text-grey" />
            <span class="text-grey">课程</span>
            <i class="el-icon-caret-bottom text-grey" />
          </div>
          <el-popover v-else width="200" trigger="click">
            <el-checkbox-group
              @change="Curriculum_change"
              v-model="Curriculum_name"
            >
              <el-checkbox
                class="padding-xs"
                :label="item.curriculum_name"
                v-for="(item, index) of CurriculumList"
                :key="index"
              />
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <i class="el-icon-link text-grey" />
              <span>课程</span>
              <i class="el-icon-caret-bottom" />
            </div>
          </el-popover>

          <el-popover width="370" trigger="click">
            <el-date-picker
              ref="datePicker"
              v-model="date"
              type="daterange"
              @change="date_change"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
            />
            <div slot="reference" class="cursor">
              <span>上课时间</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <!-- <el-checkbox-group @change="Coming_change" v-model="Coming_name">
              <el-checkbox
                class="padding-xs"
                v-for="(item, index) in Coming_list"
                :key="index"
                :label="item.name"
              />
            </el-checkbox-group> -->
            <el-radio-group @change="Coming_change" v-model="Coming_name">
              <el-radio
                v-for="(item, index) in Coming_list"
                :key="index"
                class="margin-top-sm"
                :label="item.name"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
            <div slot="reference" class="cursor">
              <span>到课状态</span>
              <i class="el-icon-caret-bottom" />
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <el-radio-group
              @change="Enrollment_change"
              v-model="Enrollment_name"
            >
              <el-radio
                v-for="(item, index) of Enrollment_Status"
                :key="index"
                class="margin-top-sm"
                :label="item.Name"
                >{{ item.Name }}</el-radio
              >
            </el-radio-group>
            <div slot="reference" class="cursor">
              <span>补课状态</span>
              <i class="el-icon-caret-bottom" />
            </div>
          </el-popover>
        </div>
        <div class="work-title-search" style="width: 30%">
          <el-input
            placeholder="姓名，手机号，微信号"
            v-model="KeyWord"
            class="input-with-select"
            size="mini"
            style="width: 66%"
          >
            <el-button
              slot="append"
              @click="select_Student"
              icon="el-icon-search"
            />
          </el-input>
          <span class="text-blue margin-left-sm cursor" @click="del_selsect"
            >清空筛选</span
          >
        </div>
      </div>
      <div
        class="work-title-status flex aligin-items-center justify-content-between flex-wrap-wrap margin-top-sm"
      >
        <div class="flex aligin-items-center flex-wrap-wrap">
          <div
            v-if="school_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>校区：</div>
            <div
              v-for="(btn, btnIndex) in school_name"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'school_name')"
                />
              </el-button>
            </div>
          </div>

          <div
            v-if="Curriculum_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>课程：</div>
            <div>
              <el-button
                size="mini"
                v-for="(btn, btnIndex) in Curriculum_name"
                :key="btnIndex"
                :class="{ 'margin-left': btnIndex > 0 }"
              >
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'Curriculum_name')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="query.time"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>上课时间：</div>
            <div>
              <el-button size="mini">
                {{ this.query.time }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus('btnIndex', 'time')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="Enrollment_name"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>补课状态：</div>
            <div>
              <el-button size="mini">
                {{ Enrollment_name }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus('btnIndex', 'Enrollment_name')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="Coming_name"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>到课状态：</div>
            <div>
              <el-button size="mini">
                {{ Coming_name }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus('btnIndex', 'Coming_name')"
                />
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-between margin-top-xl">
        <div class="flex flex-wrap-wrap">
          <!-- <el-button> 导出当前结果</el-button>
          <el-button> 发送通知公告</el-button> -->
        </div>

        <el-popover width="270" trigger="click">
          <el-checkbox-group
            v-model="Groupingdata"
            v-for="(item, index) in Groupinglist"
            :key="index"
          >
            <el-checkbox class="padding-xs" :label="item.value">{{
              item.label
            }}</el-checkbox>
          </el-checkbox-group>
          <div slot="reference">
            <!-- <el-button type="primary" size="mini">自定义显示列</el-button> -->
          </div>
        </el-popover>
      </div>
      <div class="margin-top-xl">
        <div class="flex flex-wrap-wrap">
          <!-- <el-button>插班补课</el-button>
          <el-button>新建日程补课</el-button>
          <el-button>导出当前结果</el-button>
          <el-button>批量编辑</el-button> -->
        </div>
      </div>
      <div class="myClient-text flex justify-content-between myClient-top-10">
        <div>当前结果：补课记录共计{{ rosterSize }}条</div>
      </div>
    </div>
    <div style="margin-top: 10px">
      <as-list
        :ajaxMethods="{ list: 'QueryNeedMakeUpList' }"
        :listQueryProp="query"
        @requestChange="requestChange"
        @SelectionChange="selectAllChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column sortable height="40" label="上课日期" class="cursor">
          <template slot-scope="scope">
            <span v-if="scope.row.add_time">{{
              scope.row.add_time.split("T")[0]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="student_name"
          label="学员姓名"
          height="40"
        ></el-table-column>
        <el-table-column height="40" label="联系电话" class="cursor">
          <template slot-scope="scope">
            <span> {{ scope.row.student_phone }}</span>
          </template>
        </el-table-column>
        <el-table-column height="40" label="班级/一对一" class="cursor">
          <template slot-scope="scope">
            <span> {{ scope.row.classes_name }}</span>
          </template>
        </el-table-column>
        <el-table-column height="40" label="课程" class="cursor">
          <template slot-scope="scope">
            <span> {{ scope.row.curriculum_name }}</span>
          </template>
        </el-table-column>
        <el-table-column height="40" label="上课内容" class="cursor">
          <template slot-scope="scope">
            <span> {{ scope.row.curriculum_theme }}</span>
          </template>
        </el-table-column>
        <el-table-column height="40" label="到课状态" class="cursor">
          <template slot-scope="scope">
            <span> {{ scope.row.class_arrival_name }}</span>
          </template>
        </el-table-column>
        <el-table-column height="40" label="课时" class="cursor">
          <template slot-scope="scope">
            <span> {{ scope.row.valid_class_hour }}</span>
          </template>
        </el-table-column>
        <el-table-column height="40" label="教师" class="cursor">
          <template slot-scope="scope">
            <span> {{ scope.row.teacher_name }}</span>
          </template>
        </el-table-column>
        <el-table-column height="40" label="补课状态" class="cursor">
          <template slot-scope="scope">
            <span> {{ scope.row.make_up_status_name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="操作" height="40">
          <template slot-scope="scope">
            <el-dropdown trigger="hover" @command="handleCommand">
              <label>
                操作<i class="el-icon-arrow-down el-icon-caret-bottom"></i>
              </label>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ type: 'edit', data: scope.row }">
                  <span>编辑</span>
                </el-dropdown-item>
                <!-- <el-dropdown-item>续费</el-dropdown-item> -->
                <!-- <el-dropdown-item>转课</el-dropdown-item>  -->
              </el-dropdown-menu>
            </el-dropdown>
          </template></el-table-column
        >
      </as-list>
    </div>
    <el-dialog title="补课编辑" :visible.sync="delVisible" width="60%">
      <div v-if="EditorTrainee">
        <ul class="flex">
          <li class="margin-auto">
            <div class="myClient_client_img1 margin-auto">
              <img
                v-if="
                  EditorTrainee.student_info &&
                  EditorTrainee.student_info.avatar
                "
                :src="EditorTrainee.student_info.avatar | previewImage"
              />
              <img
                v-else
                src="../../../../assets/images/portrait/default.png"
              />
            </div>
            <!-- <el-avatar :size="60" src="https://empty"  >
              <img
                src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
              />
            </el-avatar>{{avatar}} -->
            <div style="text-align: center">
              {{ EditorTrainee.student_name }}
            </div>
          </li>
          <li class="margin-auto">
            <el-radio-group
              @change="MakeUpAttend_change"
              v-model="MakeUpAttend.class_arrival"
            >
              <el-radio
                v-for="(item, index) in Arrival_list"
                :key="index"
                :label="item.Key"
                >{{ item.Name }}</el-radio
              >
            </el-radio-group>
            <div class="margin-top-sm" v-if="MakeUpAttend.class_arrival == 1">
              <el-checkbox @change="checked_change" v-model="checked"
                >迟到</el-checkbox
              ><span class="margin-left-sm">若该学生没有迟到不勾选即可</span>
            </div>
          </li>
          <li class="margin-auto">
            <span>扣除课时</span>
            <el-input-number
              v-model="MakeUpAttend.student_hours"
              controls-position="right"
              class="matgin-left-sm"
              @change="handleChange"
              :min="1"
              :max="10"
              :step="0.5"
            ></el-input-number>
            <span class="status-point margin-left-sm"></span>
            <span>可填写小数如0.5</span>
          </li>
        </ul>
      </div>
      <div style="text-align: center" v-else>无学员信息</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureDelclass">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import AsList from "@/components/as-list";
import { course, starList, withWithout } from "@/config/index";
export default {
  components: {
    AsList,
    course,
    starList,
    withWithout,
  },
  data() {
    return {
      checked: false,
      Arrival_list: [],
      MakeUpAttend: {
        student_hours: 0,
        class_arrival: 0, //class_arrival (integer, optional): 到课状态 对应枚举 ClassArrival0 = 未知 (未知), 1 = 到课 (到课), 2 = 请假 (请假), 3 = 旷课 (旷课) = ['0', '1', '2', '3'],
        class_type: 0, //0=正常，1=迟到
        data: [],
      },
      Coming_name: "", //到课状态
      Coming_list: [
        { name: "全部", value: -1 },
        { name: "请假", value: 1 },
        { name: "未记录", value: 2 },
      ],
      EditorTrainee: [],
      radio: "",
      num: "",
      delVisible: false,
      //获取数据字典
      listQuery: {
        HasRole: false,
        module: "",
        TotalCount: 0,
        PageIndex: 1,
        PageSize: 1000000,
      },
      KeyWord: "",
      date: [],
      classInput: "",
      schoolInput: "",
      Enrollment_name: "", //到课状态显示
      Enrollment_Status: [], //到课状态列表
      Curriculum_name: [], //课程显示
      CurriculumList: [], //课程列表
      school_name1: "",
      school_name: [], //学校显示
      school_list: [], //学校列表
      rosterSize: 0, //总条数
      query: {
        school_id: [],
        curr_id: [],
        time: "",
        status: -1,
        make_up_status: -1, //补课状态
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      },
      stuInput: "", // 学生姓名查询
      stuSelect: "", //搜索类型
      orderSatus: ["待支付", "已完成"], // 订单状态
      Arrears: "", //是否欠费

      schoolList: "",
      Groupingdata: [],
      Groupinglist: [
        {
          label: "教室",
          value: "1",
        },
        {
          label: "上课时间",
          value: "classes.to_class_time",
        },
        {
          label: "分班日期",
          value: "3",
        },
        {
          label: "是否升期",
          value: "4",
        },
        {
          label: "购买数量",
          value: "pay_num",
        },
        {
          label: "已用数量",
          value: "used_hours",
        },
        {
          label: "剩余数量",
          value: "over_hours",
        },
        {
          label: "总学费",
          value: "total_money",
        },
        {
          label: "实缴学费",
          value: "pay_money",
        },
        {
          label: "已用学费",
          value: "used_money",
        },
        {
          label: "剩余学费",
          value: "over_money",
        },
        {
          label: "欠费",
          value: "arrears_money",
        },
      ],
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  computed: {
    columnslistComputed() {
      return this.Groupinglist.filter(
        (t) => this.Groupingdata.findIndex((t1) => t1 == t.value) > -1
      );
    },
  },

  created() {
    var main = document.getElementById("main");
    this.get_NeedInformation();
    this.gteEnrollmentList();
  },
  methods: {
    checked_change() {
      if (this.checked) {
        this.MakeUpAttend.class_type = 1;
      } else {
        this.MakeUpAttend.class_type = 0;
      }
    },
    MakeUpAttend_change() {
      if (this.MakeUpAttend.class_arrival == 1) {
      } else {
        this.checked = false;
        this.MakeUpAttend.class_type = 0;
      }
    },
    selectAllChange(e) {},
    Coming_change() {
      if (this.Coming_name == "请假") {
        this.query.status = 1;
      } else if (this.Coming_name == "未记录") {
        this.query.status = 2;
      } else {
        this.query.status = -1;
      }
      // var Coming_list = this.Coming_list.filter((rs) => {
      //   return this.Coming_name.indexOf(rs.name) != -1;
      // });
      // this.query.status = Coming_list.map((rs) => {
      //   return rs.value;
      // });
    },
    sureDelclass() {
      //MakeUpAttendClass
      var data = [
        {
          arrange_currculum_id: this.EditorTrainee.arrange_id,
          order_currculum_id: this.EditorTrainee.id,
        },
      ];
      this.MakeUpAttend.data = data;
      this.API.MakeUpAttendClass(this.MakeUpAttend).then((res) => {
        if (res.success) {
          this.delVisible = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.query = JSON.parse( JSON.stringify(this.query));
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    handleChange(value) {
      console.log(value);
    },
    handleCommand(val) {
      // console.log(val.type,val.data);
      this.editHandle(val.type, val.data);
    },
    editHandle(type, id) {
      if (type == "edit") {
        this.delVisible = true;
        this.EditorTrainee = id;
        this.API.SystemGetSettings("ClassArrival", false).then((res) => {
          this.Arrival_list = res;
        });
      }
    },
    //清空筛选
    del_selsect() {
      this.Coming_name = []; //到课状态
      this.KeyWord = "";
      this.date = [];
      this.classInput = "";
      this.schoolInput = "";
      this.Enrollment_name = ""; //到课状态显示
      this.Curriculum_name = []; //课程显示
      this.school_name1 = "";
      this.school_name = []; //学校显示
      this.query = {
        school_id: [],
        curr_id: [],
        time: "",
        status: [],
        make_up_status: -1, //补课状态
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      };
    },
    //搜索
    select_Student() {
      this.query.KeyWord = this.KeyWord;
      this.query = JSON.parse( JSON.stringify(this.query));
    },

    //状态
    Enrollment_change(e) {
      if (this.Enrollment_name) {
        var Enrollment_Status = this.Enrollment_Status.filter((rs) => {
          return this.Enrollment_name == rs.Name;
        });
        this.query.make_up_status = Enrollment_Status;
      } else {
        this.query.make_up_status = -1;
      }
    },
    //分班日期
    date_change(e) {
      if (this.date.length) this.query.time = this.date[0] + "~" + this.date[1];
    },
    //校区
    school_change(e) {
      var school_id = this.school_list.filter((rs) => {
        return this.school_name.indexOf(rs.name) != -1;
      });
      this.query.school_id = school_id.map((rs) => {
        return rs.id;
      });
    },
    get_NeedInformation() {
      //查询校区
      var model = {
        type: 1,
        PageIndex: 1,
        PageSize: 10000,
      };
      this.API.OrganizationList(model).then((res) => {
        this.school_list = res.data.rows;
      });
      this.getCurriculumList(); //课程类别下的课程

      this.API.SystemGetSettings("MakeUpStatus", false).then((res) => {
        this.Enrollment_Status = res || [];
      });
    },
    //课程分类下的课程
    getCurriculumList() {
      var model = {
        open_school: this.query.school_id,
        KeyWord: "",
        PageIndex: 1,
        PageSize: 15,
      };
      this.API.getCurriculumList(model).then((res) => {
        this.CurriculumList = res.data.rows || [];
      });
    },
    //选择课程
    Curriculum_change(e) {
      // CurriculumList
      var CurriculumList = this.CurriculumList.filter((rs) => {
        return this.Curriculum_name.indexOf(rs.curriculum_name) != -1;
      });
      this.query.curr_id = CurriculumList.map((rs) => {
        return rs.id;
      });
    },

    //删除校区搜索
    del_Choose_Campus(index, del) {
      if (del == "school_name") {
        //清除校区
        this.school_name.splice(index, 1);
        var school_id = this.school_list.filter((rs) => {
          return this.school_name.indexOf(rs.name) != -1;
        });
        this.query.school_id = school_id.map((rs) => {
          return rs.id;
        });
        this.getCurriculumList(); //获取课程类别下的课程
        // this.query.school_id = [];
      } else if (del == "Enrollment_name") {
        //清除学员到课状态
        this.Enrollment_name = "";
        this.Enrollment_change();
      } else if (del == "Curriculum_name") {
        //清除课程
        this.Curriculum_name.splice(index, 1);
        var Curriculum_name = this.CurriculumList.filter((rs) => {
          return this.Curriculum_name.indexOf(rs.curriculum_name) != -1;
        });
        this.query.curr_id = Curriculum_name.map((rs) => {
          return rs.id;
        });
      } else if (del == "time") {
        //分班日期
        this.query.time = "";
        this.date = [];
      } else if (del == "Coming_name") {
        //分班日期
        this.Coming_name = "";
        this.Coming_change();
      }
    },

    //as-list接口请求返回
    requestChange(res) {
      this.rosterSize = res.total;
      // if (res.outer_data) {
      //   this.arrearMoney = res.outer_data.arrearMoney;
      // }
    },
    getIntroducer(keyword) {
      var model = {
        // AccountType: [5],
        post_tag:['学管'],
        PageIndex: this.getIntroducer_PageIndex,
        PageSize: this.getIntroducer_PageSize,
        KeyWord: keyword,
      };
      return this.API.accountQuery(model);
    },
    querySearch(queryString, cb) {
      this.getIntroducer_PageIndex = 1;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;

        cb(res.data.rows || []);
      });
    },
    //报读列表
    //获取报读列表筛选数据字典
    gteEnrollmentList() {
      //获取学员到课状态
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.EnrollmentStatus)
        .then((res) => {
          this.EnrollmentStatus = res.data.rows;
        });
      //获取学员到课状态
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.ApplyForCampus)
        .then((res) => {
          this.ApplyForCampus = res.data.rows;
        });
      //获取课程大类
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.CourseClass)
        .then((res) => {
          this.CourseClass = res.data.rows;
        });
    },

    loadMoreStudent_Enrolling(queryString, cb) {
      this.getIntroducer_PageIndex_Enrolling++;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;
        cb(list);
      });
    },

    //报读列表结束

    JumpUrl(item) {
      this.$router.push("/educationCenter/studentInformation?id=" + item.id);
    },
    starStatus(index) {
      this.starS.splice(index, 1);
    },

    openDate(t) {
      if (t) {
        this.$refs.evaluation.pickerVisible = true;
      } else {
        this.$refs.datePicker.pickerVisible = true;
      }
    },
    classopenDate(t) {
      this.$refs.classdatePicker.pickerVisible = true;
    },
    handleClick(row) {},

    handleChange(value) {},
    goNav(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style  scoped>
>>> el-table-column {
  height: 40px;
}
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}
.work-title-content div {
  padding: 0 5px 0 0px;
}
.total {
  width: 100%;
}

.shool {
  margin: 0px 10px;
}

.configure {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

>>> .el-select .el-input {
  width: 110px !important;
}

.configure {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}
.status-point {
  background-color: #0081ff;
  margin-right: 5px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.el-select .el-input {
  width: 105px;
}
</style>
