<template>
  <div class="total padding-lr-lg">
    <div class="work-title bg-white">
      <div
        class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap"
      >
        <div
          class="work-title-content flex aligin-items-center flex-wrap-wrap"
          style="width: 60%"
        >
          <el-popover width="370" trigger="click">
            <el-date-picker
              ref="datePicker"
              v-model="date"
              type="daterange"
              @change="date_change"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
            />
            <div slot="reference" class="cursor">
              <span>上课日期</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <el-input
              v-model="school_name1"
              placeholder=""
              size="mini"
            ></el-input>
            <el-checkbox-group @change="school_change" v-model="school_name">
              <el-checkbox
                class="padding-xs"
                :label="item.name"
                v-for="(item, index) of school_list"
                :key="index"
              ></el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <span>校区</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
          <el-popover width="250" trigger="click">
            <el-input
              v-model="schoolInput"
              placeholder=""
              size="mini"
            ></el-input>
            <el-checkbox-group
              @change="courseClass_change"
              v-model="courseClass_name"
            >
              <el-checkbox
                class="padding-xs"
                :label="item.title"
                v-for="(item, index) of courseClassList"
                :key="index"
              />
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <span>课程大类</span>
              <i class="el-icon-caret-bottom" />
            </div>
          </el-popover>
          <div v-if="!query.Curriculum_Type.length" slot="reference">
            <i class="el-icon-link text-grey" />
            <span class="text-grey">课程</span>
            <i class="el-icon-caret-bottom text-grey" />
          </div>
          <el-popover v-else width="350" trigger="click">
            <el-input v-model="classInput" placeholder="" size="mini" />
            <el-checkbox-group
              @change="Curriculum_change"
              v-model="Curriculum_name"
            >
              <el-checkbox
                class="padding-xs"
                :label="item.curriculum_name"
                v-for="(item, index) of CurriculumList"
                :key="index"
              />
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <i class="el-icon-link text-grey" />
              <span>课程</span>
              <i class="el-icon-caret-bottom" />
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <el-checkbox-group @change="Subject_change" v-model="Subject_name">
              <el-checkbox
                class="padding-xs"
                :label="item.title"
                v-for="(item, index) of Subject_Type"
                :key="index"
              />
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <span>科目</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
          <el-popover width="200" trigger="click">
            <el-checkbox-group @change="teacher_change" v-model="teacher_name">
              <el-checkbox
                class="padding-xs"
                :label="item.full_name"
                v-for="(item, index) of ArrangeTeachers_List"
                :key="index"
              />
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <span>教师</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>

          <el-popover width="200" trigger="click">
            <el-checkbox-group
              @change="assistant_change"
              v-model="assistant_name"
            >
              <el-checkbox
                class="padding-xs"
                :label="item.full_name"
                v-for="(item, index) of ArrangeTeachers_List"
                :key="index"
              />
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <span>助教</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>

          <el-popover width="150" trigger="click">
            <el-checkbox-group
              @change="Enrollment_change"
              v-model="Enrollment_name"
            >
              <el-checkbox
                class="padding-xs"
                :label="item.Name"
                v-for="(item, index) of Enrollment_Status"
                :key="index"
              />
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <span>补课状态</span>
              <i class="el-icon-caret-bottom" />
            </div>
          </el-popover>
        </div>
        <div class="work-title-search" style="width: 30%">
          <el-input
            placeholder="姓名，手机号，微信号"
            v-model="KeyWord"
            class="input-with-select"
            size="mini"
            style="width: 66%"
          >
            <el-button
              slot="append"
              @click="select_Student"
              icon="el-icon-search"
            />
          </el-input>
          <span class="text-blue margin-left-sm cursor" @click="del_selsect"
            >清空筛选</span
          >
        </div>
      </div>
      <div
        class="work-title-status flex aligin-items-center justify-content-between flex-wrap-wrap margin-top-sm"
      >
        <div class="flex aligin-items-center flex-wrap-wrap">
          <div
            v-if="school_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>校区：</div>
            <div
              v-for="(btn, btnIndex) in school_name"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'school_name')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="courseClass_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>课程类别：</div>
            <div>
              <el-button
                size="mini"
                v-for="(btn, btnIndex) in courseClass_name"
                :key="btnIndex"
                :class="{ 'margin-left': btnIndex > 0 }"
              >
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'courseClass_name')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="Curriculum_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>课程：</div>
            <div>
              <el-button
                size="mini"
                v-for="(btn, btnIndex) in Curriculum_name"
                :key="btnIndex"
                :class="{ 'margin-left': btnIndex > 0 }"
              >
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'Curriculum_name')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="query.division_time"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>上课时间：</div>
            <div>
              <el-button size="mini">
                {{ this.query.division_time }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus('btnIndex', 'division_time')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="Subject_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>科目：</div>
            <div
              v-for="(btn, btnIndex) in Subject_name"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'Subject_name')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="teacher_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>教师：</div>
            <div
              v-for="(btn, btnIndex) in teacher_name"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'teacher_name')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="Enrollment_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>到课状态：</div>
            <div
              v-for="(btn, btnIndex) in Enrollment_name"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'Enrollment_name')"
                />
              </el-button>
            </div>
          </div>

          <div
            v-if="assistant_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>助教：</div>
            <div
              v-for="(btn, btnIndex) in assistant_name"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'assistant_name')"
                />
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-between margin-top-xl">
        <div class="flex flex-wrap-wrap">
          <!-- <el-button> 导出当前结果</el-button>
          <el-button> 发送通知公告</el-button> -->
        </div>

        <el-popover width="270" trigger="click">
          <el-checkbox-group
            v-model="Groupingdata"
            v-for="(item, index) in Groupinglist"
            :key="index"
          >
            <el-checkbox class="padding-xs" :label="item.value">{{
              item.label
            }}</el-checkbox>
          </el-checkbox-group>
          <div slot="reference">
            <el-button type="primary" size="mini">自定义显示列</el-button>
          </div>
        </el-popover>
      </div>
      <div class="margin-top-xl">
        <div class="flex flex-wrap-wrap">
          <!-- <el-button>插班补课</el-button>
          <el-button>新建日程补课</el-button>
          <el-button>导出当前结果</el-button>
          <el-button>批量编辑</el-button> -->
        </div>
      </div>
      <div class="myClient-text flex justify-content-between myClient-top-10">
        <div>当前结果：记录共计{{ rosterSize }}条</div>
      </div>
    </div>
    <div style="margin-top: 10px">
      <as-list
        :ajaxMethods="{ list: 'getArrangeCurriculumList' }"
        :listQueryProp="query"
        @requestChange="requestChange"
        @SelectionChange="selectAllChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column sortable height="40" label="学员名字" class="cursor">
          <template slot-scope="scope">
            <router-link
              replace
              :to="{
                path: '/educationCenter/studentInformation',
                query: { id: scope.row.student_id },
              }"
            >
              <span class="cursor text-blue">
                {{ scope.row.student_name }}</span
              >
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="上课日期">
          <template slot-scope="scope">
            <span v-if="scope.row.class_begin_date">
              {{ scope.row.class_begin_date.split("T")[0] }}</span
            >
          </template></el-table-column
        >
        <el-table-column
          prop="arrange_classes_name"
          label="班级/一对一"
        ></el-table-column>
        <el-table-column
          prop="arrange_teacher_name"
          label="上课教师"
        ></el-table-column>
        <!-- <el-table-column prop="real" label="到课"></el-table-column>
        <el-table-column prop="leavecount" label="请假"></el-table-column>
        <el-table-column prop="truancycount" label="旷课"></el-table-column>
        <el-table-column prop="student_time" label="学员课时"></el-table-column>
        <el-table-column prop="teacher_time" label="教师课时"></el-table-column> -->
        <el-table-column prop="add_time" label="创建日期">
          <template slot-scope="scope">
            <span v-if="scope.row.add_time">
              {{ scope.row.add_time.split("T")[0] }}</span
            >
          </template></el-table-column
        >
        <el-table-column prop="curriculum_date" label="上课时段">
          <template slot-scope="scope">
            <span>
              {{ scope.row.class_begin_time_start }}-{{
                scope.row.class_begin_time_end
              }}</span
            >
          </template></el-table-column
        >

        <template v-for="(item, index) in columnslistComputed">
          <el-table-column
            v-if="['used_hours', 'over_hours'].includes(item.value)"
            :key="index"
            sortable
            height="40"
            :prop="item.value"
            :label="item.label"
          >
          </el-table-column>
          <el-table-column
            v-else
            :key="index"
            height="40"
            :prop="item.value"
            :label="item.label"
          >
          </el-table-column>
        </template>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="Delete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </as-list>
    </div>
  </div>
</template>
<script>
import AsList from "@/components/as-list";
import { course, starList, withWithout } from "@/config/index";
export default {
  components: {
    AsList,
    course,
    starList,
    withWithout,
  },
  data() {
    return {
      ids: "",
      assistant_name: [], //助教
      ArrangeTeachers_List: [], //教师列表
      teacher_name: [], //教师
      Subject_name: [], //科目
      Subject_Type: [],
      SubjectQuery: {
        module: "SubjectType",
        parentId: 10,
      },
      //获取数据字典
      listQuery: {
        HasRole: false,
        module: "",
        TotalCount: 0,
        PageIndex: 1,
        PageSize: 1000000,
      },
      teacherQueryData: {
        post_type: [8],
        KeyWord: "",
        PageSize: 20,
        PageIndex: 1,
      },
      KeyWord: "",
      date: [],
      classInput: "",
      schoolInput: "",
      Enrollment_name: [], //到课状态显示
      Enrollment_Status: [], //到课状态列表
      courseClassList: [], //课程大类列表
      courseClass_name: [], //课程大类显示
      Curriculum_name: [], //课程显示
      CurriculumList: [], //课程列表
      school_name1: "",
      school_name: [], //学校显示
      school_list: [], //学校列表
      rosterSize: 0, //总条数
      query: {
        school_id: [],
        Curriculum_Type: [],
        curriculum_id: [],
        division_time: "",
        status: [],
        student_name: "",
        phone: "",
        class_name: "",
        teacher_id: "",
        assistant_id: [],
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      },
      stuInput: "", // 学生姓名查询
      stuSelect: "", //搜索类型
      orderSatus: ["待支付", "已完成"], // 订单状态
      Arrears: "", //是否欠费

      schoolList: "",
      Groupingdata: [],
      Groupinglist: [
        {
          label: "所属课程",
          value: "curriculum_name",
        },
        {
          label: "助教",
          value: "arrange_assistant_name",
        },
        {
          label: "实到/应到",
          value: "Population",
        },
        {
          label: "迟到",
          value: "latecount",
        },
        {
          label: "学费消耗",
          value: "2",
        },
        {
          label: "补课",
          value: "3",
        },
        {
          label: "上课校区",
          value: "school_name",
        },
        {
          label: "科目",
          value: "arrange_subject_name",
        },
        {
          label: "上课内容",
          value: "course_content",
        },
        {
          label: "备注",
          value: "remker",
        },
        {
          label: "创建人",
          value: "createusername",
        },
      ],
    };
  },
  watch: {
    Visible(val) {
      this.dialogVisible = val;
    },
  },
  computed: {
    columnslistComputed() {
      return this.Groupinglist.filter(
        (t) => this.Groupingdata.findIndex((t1) => t1 == t.value) > -1
      );
    },
  },

  created() {
    var main = document.getElementById("main");
    this.get_NeedInformation();
    this.gteEnrollmentList();
  },
  methods: {
    selectAllChange(e) {
      // var ids =[];
      // for (let index = 0; index < e.length; index++) {
      //    ids.push(e[index].id);
      // }
      //  this.ids = ids.join(',');
    },
    //教师
    teacher_change() {
      var teacher_name = this.ArrangeTeachers_List.filter((rs) => {
        return this.teacher_name.indexOf(rs.full_name) != -1;
      });
      this.query.teacher_id = teacher_name.map((rs) => {
        return rs.id;
      });
    },
    //助教
    assistant_change() {
      var assistant_name = this.ArrangeTeachers_List.filter((rs) => {
        return this.assistant_name.indexOf(rs.full_name) != -1;
      });
      this.query.assistant_id = assistant_name.map((rs) => {
        return rs.id;
      });
    },
    //科目
    Subject_change() {
      var school_id = this.Subject_Type.filter((rs) => {
        return this.Subject_name.indexOf(rs.title) != -1;
      });
      this.query.subject_id = school_id.map((rs) => {
        return rs.id;
      });
    },
    //清空筛选
    del_selsect() {
      this.KeyWord = "";
      this.date = [];
      this.classInput = "";
      this.schoolInput = "";
      this.Enrollment_name = []; //到课状态显示
      this.Enrollment_Status = []; //到课状态列表
      this.courseClassList = []; //课程大类列表
      this.courseClass_name = []; //课程大类显示
      this.Curriculum_name = []; //课程显示
      this.CurriculumList = []; //课程列表
      this.school_name1 = "";
      this.school_name = []; //学校显示
      this.school_list = []; //学校列表
      this.query = {
        school_id: [],
        Curriculum_Type: [],
        curriculum_id: [],
        division_time: "",
        status: [],
        student_name: "",
        phone: "",
        class_name: "",
        teacher_name: "",
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      };
    },
    //搜索
    select_Student() {
      this.query.KeyWord = this.KeyWord;
    },

    //状态
    Enrollment_change(e) {
      var Enrollment_Status = this.Enrollment_Status.filter((rs) => {
        return this.Enrollment_name.indexOf(rs.Name) != -1;
      });
      this.query.status = Enrollment_Status.map((rs) => {
        return rs.Key;
      });
    },
    //分班日期
    date_change(e) {
      if (this.date.length)
        this.query.division_time = this.date[0] + "~" + this.date[1];
    },
    //校区
    school_change(e) {
      var school_id = this.school_list.filter((rs) => {
        return this.school_name.indexOf(rs.name) != -1;
      });
      this.query.school_id = school_id.map((rs) => {
        return rs.id;
      });
    },
    get_NeedInformation() {
      //查询校区
      var model = {
        type: 1,
        PageIndex: 1,
        PageSize: 10000,
        Sort: [
          {
            Field: "id",
            Type: 0,
          },
        ],
      };
      this.API.OrganizationList(model).then((res) => {
        this.school_list = res.data.rows;
      });
      // 获取课程类别
      this.listQuery.module = "course_class";
      this.$Dictionary.GetDictionarys(this.listQuery).then((res) => {
        this.courseClassList = res.data.rows;
        this.courseClassList = this.courseClassList.filter((x) => {
          return x.parent_id != 0;
        });
        this.$forceUpdate();
      });
      this.API.SystemGetSettings("MakeUpStatus", false).then((res) => {
        this.Enrollment_Status = res || [];
      });
      //查询科目
      this.$Dictionary.GetDictionaryTree(this.SubjectQuery).then((res) => {
        this.Subject_Type = res.data || [];
      });
var ArrangeTeachers = {
        // AccountType: [5],
        post_tag:['教师'],
        PageIndex: 1,
        PageSize: 100,
      }; 
      this.API.accountQuery(ArrangeTeachers).then((res) => {
        this.ArrangeTeachers_List = res.data.rows;
      });
    
      // this.API.accountQuery(this.teacherQueryData).then((res) => {
      //   this.ArrangeTeachers_List = res.data.rows;
      // });
    },
    //课程分类下的课程
    getCurriculumList() {
      var model = {
        curriculum_type: this.query.Curriculum_Type,
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10000,
      };
      this.API.getCurriculumList(model).then((res) => {
        this.CurriculumList = res.data.rows || [];
      });
    },
    //选择课程
    Curriculum_change(e) {
      // CurriculumList
      var CurriculumList = this.CurriculumList.filter((rs) => {
        return this.Curriculum_name.indexOf(rs.curriculum_name) != -1;
      });
      this.query.curriculum_id = CurriculumList.map((rs) => {
        return rs.id;
      });
    },
    //选择课程类别
    courseClass_change() {
      var courseClass_list = this.courseClassList.filter((rs) => {
        return this.courseClass_name.indexOf(rs.title) != -1;
      });
      this.query.Curriculum_Type = courseClass_list.map((rs) => {
        return rs.id;
      });
      this.getCurriculumList(); //课程类别下的课程
    },
    //删除校区搜索
    del_Choose_Campus(index, del) {
      if (del == "school_name") {
        //清除校区
        this.school_name.splice(index, 1);
        var school_id = this.school_list.filter((rs) => {
          return this.school_name.indexOf(rs.name) != -1;
        });
        this.query.school_id = school_id.map((rs) => {
          return rs.id;
        });
        // this.query.school_id = [];
      } else if (del == "courseClass_name") {
        //清除课程类别
        this.courseClass_name.splice(index, 1);
        var courseClassList = this.courseClassList.filter((rs) => {
          return this.courseClass_name.indexOf(rs.title) != -1;
        });
        this.query.Curriculum_Type = courseClassList.map((rs) => {
          return rs.id;
        });
        this.getCurriculumList(); //获取课程类别下的课程
      } else if (del == "Enrollment_name") {
        //清除学员到课状态
        this.Enrollment_name.splice(index, 1);
        var Enrollment_name = this.Enrollment_Status.filter((rs) => {
          return this.Enrollment_name.indexOf(rs.Name) != -1;
        });
        this.query.status = Enrollment_name.map((rs) => {
          return rs.Key;
        });
        this.getCurriculumList(); //获取课程类别下的课程
      } else if (del == "Curriculum_name") {
        //清除课程
        this.Curriculum_name.splice(index, 1);
        var Curriculum_name = this.CurriculumList.filter((rs) => {
          return this.Curriculum_name.indexOf(rs.curriculum_name) != -1;
        });
        this.query.curriculum_id = Curriculum_name.map((rs) => {
          return rs.id;
        });
      } else if (del == "division_time") {
        //分班日期
        this.query.division_time = "";
        this.date = [];
      } else if (del == "Subject_name") {
        //清除科目
        this.Subject_name.splice(index, 1);
        var subject_id = this.Subject_Type.filter((rs) => {
          return this.Subject_name.indexOf(rs.title) != -1;
        });
        this.query.subject_id = subject_id.map((rs) => {
          return rs.id;
        });
      } else if (del == "teacher_name") {
        //删除教师
        this.teacher_name.splice(index, 1);
        this.teacher_change();
      } else if (del == "assistant_name") {
        //删除助教
        this.assistant_name.splice(index, 1);
        this.assistant_change();
      }
    },

    //as-list接口请求返回
    requestChange(res) {
      this.rosterSize = res.total;
      // if (res.outer_data) {
      //   this.arrearMoney = res.outer_data.arrearMoney;
      // }
    },
    getIntroducer(keyword) {
      var model = { 
        post_tag:['学管'],
        PageIndex: this.getIntroducer_PageIndex,
        PageSize: this.getIntroducer_PageSize,
        KeyWord: keyword,
      };
      return this.API.accountQuery(model);
    },
    querySearch(queryString, cb) {
      this.getIntroducer_PageIndex = 1;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;

        cb(res.data.rows || []);
      });
    },
    //报读列表
    //获取报读列表筛选数据字典
    gteEnrollmentList() {
      //获取学员到课状态
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.EnrollmentStatus)
        .then((res) => {
          this.EnrollmentStatus = res.data.rows;
        });
      //获取学员到课状态
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.ApplyForCampus)
        .then((res) => {
          this.ApplyForCampus = res.data.rows;
        });
      //获取课程大类
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.CourseClass)
        .then((res) => {
          this.CourseClass = res.data.rows;
        });
    },

    loadMoreStudent_Enrolling(queryString, cb) {
      this.getIntroducer_PageIndex_Enrolling++;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;
        cb(list);
      });
    },

    //报读列表结束

    JumpUrl(item) {
      this.$router.push("/educationCenter/studentInformation?id=" + item.id);
    },
    starStatus(index) {
      this.starS.splice(index, 1);
    },

    openDate(t) {
      if (t) {
        this.$refs.evaluation.pickerVisible = true;
      } else {
        this.$refs.datePicker.pickerVisible = true;
      }
    },
    classopenDate(t) {
      this.$refs.classdatePicker.pickerVisible = true;
    },
    handleClick(row) {
      var _this = this;
      if (row.id) { 
        _this.$router.push({
          path: "/educationCenter/addRemembertoClass",
          query: { classid: row.clasess.id,id:row.arrange_id,date:row.class_begin_date.split("T")[0], type: "edit" },
        });
      } else {
        this.$message.error("请先点击一个信息在试试");
      }
    },

    Delete(row) {
      if (row) {
        this.ids = row.id;
      }
      this.$confirm("确定删除上课记录吗?", "删除记录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.API.CurriculumAttendChangestatus(this.ids).then((res) => {
            if (res.success) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.query.PageIndex = 2;
              this.query.PageIndex = 1;
            } else {
              this.$message({
                message: "删除失败！",
                type: "warning",
              });
            }
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },

    handleChange(value) {},
    goNav(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style  scoped>
>>> el-table-column {
  height: 40px;
}
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}
.work-title-content div {
  padding: 0 5px 0 0px;
}
.total {
  width: 100%;
}

.shool {
  margin: 0px 10px;
}

.configure {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

>>> .el-select .el-input {
  width: 110px !important;
}

.configure {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.el-select .el-input {
  width: 105px;
}
</style>
