<template>
	<div>
		<div class="work-title bg-white flex justify-content-center">
			<el-button round @click="colorChange(5)" :class="color5 ? 'color-box' : ''">教师课时</el-button>
			<el-button :class="color6 ? 'color-box' : ''" round @click="colorChange(6)">学员课时</el-button>
		</div>
		<div v-show="color5">
			<div class="work-title bg-white margin-top padding">
				<div class="flex flex-wrap-wrap">
					<div>
						<el-select v-model="Campus" placeholder="按上课校区" style="width: 120px">
							<el-option label="按上课校区" value="1"></el-option>
							<el-option label="按所属校区" value="2"></el-option>
						</el-select>
					</div>
					<div class="margin-left">
						<el-select v-model="allCampuses" placeholder="请选择校区">
							<el-option label="全部校区" value="1"></el-option>
							<el-option label="驿都城校区" value="2"></el-option>
						</el-select>
					</div>
					<div class="margin-left">
						<el-select v-model="subject" multiple filterable allow-create default-first-option
							style="width: 300px" placeholder="请选择科目">
							<el-option label="全部科目" value="1" />
							<el-option label="物理大班" value="2" />
							<el-option label="化学大班" value="3" />
						</el-select>
					</div>
					<div class="margin-left">
						<el-select v-model="courseCategories" placeholder="请选择课程类别">
							<el-option label="全部课程类别" value="1"></el-option>
							<el-option label="理科10人班" value="2"></el-option>
						</el-select>
					</div>
				</div>
				<div class="margin-tb">
					<i class="iconfont icon-yuandianxiao line-blue"></i>
					<span>可统计出教师在上课校区的上课情况</span>
				</div>
				<div class="flex flex-wrap-wrap">
					<div>
						<el-date-picker v-model="dates" type="datetimerange" :picker-options="pickerOptions"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							align="right"></el-date-picker>
					</div>
					<div class="margin-left">
						<el-select v-model="chooseTeacher" placeholder="请选择教师" style="width: 300px">
							<el-option label="全部教师" value="1"></el-option>
							<el-option label="张老师  15893847657" value="2"></el-option>
						</el-select>
					</div>
		
					<div class="margin-left">
						<el-select v-model="courseCategories" placeholder="请选择" disabled>
							<el-option label="全部课程类别" value="1"></el-option>
							<el-option label="理科10人班" value="2"></el-option>
						</el-select>
					</div>
				</div>
			</div>
			<div class="flex justify-content-flexEnd margin-top-xl">
				<el-button type="primary">计算</el-button>
				<el-button type="primary">计算并导出</el-button>
			</div>
		
			<div style="margin-top: 30px">
				<as-list :initdataList="classHoursList">
					<el-table-column type="expand">
						<template slot-scope="props">
							<el-table :data="detailMessage">
								<el-table-column align="center" prop="classDate" label="上课日期">
								</el-table-column>
								<el-table-column align="center" prop="classCampus" label="上课校区">
								</el-table-column>
								<el-table-column align="center" prop="teacher" label="班级/一对一">
								</el-table-column>
								<el-table-column align="center" prop="teacher" label="科目"></el-table-column>
								<el-table-column align="center" prop="teacher" label="到课人次">
								</el-table-column>
								<el-table-column align="center" prop="teacher" label="扣课时人次(人)">
								</el-table-column>
								<el-table-column align="center" prop="studentTime" sortable label="学员课时">
								</el-table-column>
								<el-table-column align="center" prop="teacherTime" label="教师课时">
								</el-table-column>
								<el-table-column align="center" prop="assistantTime" label="助教课时">
								</el-table-column>
								<el-table-column align="center" prop="tuition" label="学费消耗(元)">
								</el-table-column>
							</el-table>
						</template>
					</el-table-column>
		
					<el-table-column prop="teacher" label="教师"></el-table-column>
		
					<el-table-column align="center" prop="total" width="90"></el-table-column>
					<el-table-column align="center" prop="Attendance" label="到课人次(人)" width="120">
					</el-table-column>
					<el-table-column align="center" prop="deductionsNum" label="扣课时人次(人)" width="120">
					</el-table-column>
					<el-table-column align="center" prop="studentTime" label="学员课时" width="90">
					</el-table-column>
					<el-table-column align="center" prop="teacherTime" label="教师课时" width="90">
					</el-table-column>
					<el-table-column align="center" prop="assistantTime" label="助教课时" width="90">
					</el-table-column>
					<el-table-column align="center" prop="tuition" label="学费消耗(元)" width="120">
					</el-table-column>
				</as-list>
			</div>
		</div>
		<div v-show="color6">
			<div class="work-title bg-white margin-top padding">
				<div class="flex flex-wrap-wrap">
					<div>
						<el-select v-model="studentClassHours.allCampuses" placeholder="请选择校区"
							style="width: 300px">
							<el-option label="全部校区" value="1"></el-option>
							<el-option label="驿都城校区" value="2"></el-option>
						</el-select>
					</div>
					<div class="margin-left">
						<el-select v-model="studentClassHours.subject" multiple filterable allow-create
							default-first-option style="width: 300px" placeholder="请选择科目">
							<el-option label="全部科目" value="1" />
							<el-option label="物理大班" value="2" />
							<el-option label="化学大班" value="3" />
						</el-select>
					</div>
					<div class="margin-left">
						<el-select v-model="studentClassHours.courseCategories" placeholder="请选择课程类别"
							style="width: 300px">
							<el-option label="全部课程类别" value="1"></el-option>
							<el-option label="理科10人班" value="2"></el-option>
						</el-select>
					</div>
				</div>
		
				<div class="flex flex-wrap-wrap margin-top">
					<div>
						<el-date-picker v-model="studentClassHours.dates" type="datetimerange"
							:picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" align="right" style="width: 300px"></el-date-picker>
					</div>
					<div class="margin-left">
						<el-select v-model="studentClassHours.chooseStu" placeholder="请选择教师"
							style="width: 300px">
							<el-option label="全部学员" value="1"></el-option>
							<el-option label="张龙  15893847657" value="2"></el-option>
						</el-select>
					</div>
		
					<div class="margin-left">
						<el-select v-model="studentClassHours.courseCategories" placeholder="请选择" disabled
							style="width: 300px">
							<el-option label="全部课程类别" value="1"></el-option>
							<el-option label="理科10人班" value="2"></el-option>
						</el-select>
					</div>
				</div>
				<div class="margin-top">
					<el-select v-model="studentClassHours.chooseStu" multiple filterable allow-create
						default-first-option placeholder="请选择班级" style="width: 300px">
						<el-option label="全部学员" value="1"></el-option>
						<el-option label="张龙  15893847657" value="2"></el-option>
					</el-select>
				</div>
			</div>
			<div class="flex justify-content-flexEnd margin-top-xl">
				<el-button type="primary">计算</el-button>
				<el-button type="primary">计算并导出</el-button>
			</div>
		
			<div style="margin-top: 30px">
				<as-list :initdataList="classHoursList">
					<el-table-column type="expand">
						<template slot-scope="props">
							<el-table :data="detailMessage">
								<el-table-column align="center" prop="classDate" label="上课日期">
								</el-table-column>
								<el-table-column align="center" prop="classCampus" label="上课校区">
								</el-table-column>
								<el-table-column align="center" prop="teacher" label="班级/一对一">
								</el-table-column>
								<el-table-column align="center" prop="teacher" label="课程类别">
								</el-table-column>
								<el-table-column align="center" prop="teacher" label="课程名称">
								</el-table-column>
								<el-table-column align="center" prop="teacher" label="科目"></el-table-column>
								<el-table-column align="center" prop="studentTime" sortable label="到课状态">
								</el-table-column>
								<el-table-column align="center" prop="teacherTime" label="学员课时">
								</el-table-column>
								<el-table-column align="center" prop="tuition" label="学费消耗(元)">
								</el-table-column>
							</el-table>
						</template>
					</el-table-column>
		
					<el-table-column prop="teacher" label="学员"></el-table-column>
		
					<el-table-column align="center" prop="total" width="90"></el-table-column>
					<el-table-column align="center" prop="Attendance" label="应上课次" width="120">
					</el-table-column>
					<el-table-column align="center" prop="deductionsNum" label="学员课时" width="120">
					</el-table-column>
					<el-table-column align="center" prop="tuition" label="学费消耗(元)" width="120">
					</el-table-column>
				</as-list>
			</div>
		</div>
						
	</div>
</template>

<script>
	import AsList from "@/components/as-list";
	export default{
		data(){
			return{
				color5: true,
				color6: false,
				Campus:'',
				allCampuses:'',
				subject:'',
				courseCategories:'',
				dates:'',
				chooseTeacher:'',
				pickerOptions: {
					shortcuts: [{
							text: "一周内",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "一个月内",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "三个月内",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "半年内",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 184);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "一年内",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
								picker.$emit("pick", [start, end]);
							},
						},
					],
				},
				studentClassHours: {
					Campus: "",
					allCampuses: "",
					subject: [],
					courseCategories: "",
					dates: "",
					chooseTeacher: "",
					chooseStu: [],
				},
				classHoursList: [{
					teacher: "张龙|所属校区：驿都城校区",
					total: "总计",
					Attendance: "1", //到课人次
					deductionsNum: "1", //扣课时人次、
					studentTime: "14.00", //学员课时
					teacherTime: "0.00", //教师课时
					assistantTime: "2.00", //助教课时
					tuition: "2537.00", //学费消耗
				}, ],
				detailMessage: [{
					classDate: "",
					classCampus: "",
					teacher: "",
					studentTime: "",
					teacherTime: "",
					tuition: "",
				}, ],
			}
		},
		components: {
			AsList,
		},
		methods:{
			colorChange(type) {
				if (type == 5) {
					this.color5 = true;
					this.color6 = false;
				} else if (type == 6) {
					this.color6 = true;
					this.color5 = false;
				}
			},
		}
	}
</script>

<style>
	.color-box {
		color: #0081ff;
		border: 1px solid #8cc5ff;
		background-color: #e8f5ff;
	}
</style>
