<template>
	<div>
		<div class="work-title bg-white margin-top padding">
			<div class="flex flex-wrap-wrap">
				<div>
					<el-select v-model="Audition.Campus" placeholder="按上课校区" style="width: 120px">
						<el-option label="按上课校区" value="1"></el-option>
						<el-option label="按所属校区" value="2"></el-option>
					</el-select>
				</div>
				<div class="margin-left">
					<el-select v-model="Audition.allCampuses" placeholder="请选择校区">
						<el-option label="全部校区" value="1"></el-option>
						<el-option label="驿都城校区" value="2"></el-option>
					</el-select>
				</div>
				<div class="margin-left">
					<el-select v-model="Audition.subject" multiple filterable allow-create
						default-first-option style="width: 300px" placeholder="请选择课程">
						<el-option label="全部课程" value="1" />
						<el-option label="物理大班" value="2" />
						<el-option label="化学大班" value="3" />
					</el-select>
				</div>
				<div class="margin-left">
					<el-date-picker v-model="Audition.dates" type="datetimerange"
						:picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" align="right"></el-date-picker>
				</div>
			</div>
			<div class="margin-tb">
				<i class="iconfont icon-yuandianxiao line-blue"></i>
				<span>可统计出教师在上课校区的上课情况</span>
			</div>
			<div class="flex flex-wrap-wrap">
				<div class="margin-left">
					<el-select v-model="Audition.chooseTeacher" placeholder="请选择教师" style="width: 300px">
						<el-option label="全部教师" value="1"></el-option>
						<el-option label="张老师  15893847657" value="2"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div>
			<el-button type="primary" style="margin: 20px auto">计算</el-button>
		</div>
		
		<as-list :initdataList="classHoursList">
			<el-table-column prop="Attendance" label="教师"></el-table-column>
			<el-table-column align="center" prop="Attendance" label="试听人数" width="120"></el-table-column>
			<el-table-column align="center" prop="Attendance" label="试听时长" width="120"></el-table-column>
			<el-table-column align="center" prop="studentTime" label="试听转换人数" width="120"></el-table-column>
		</as-list>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				classHoursList: [{
					teacher: "张龙|所属校区：驿都城校区",
					total: "总计",
					Attendance: "1", //到课人次
					deductionsNum: "1", //扣课时人次、
					studentTime: "14.00", //学员课时
					teacherTime: "0.00", //教师课时
					assistantTime: "2.00", //助教课时
					tuition: "2537.00", //学费消耗
				}, ],
				Audition: {
					Campus: "",
					allCampuses: "",
					subject: [],
					courseCategories: "",
					dates: "",
					chooseTeacher: "",
					chooseStu: "",
				},
				pickerOptions: {
					shortcuts: [{
							text: "一周内",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "一个月内",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "三个月内",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "半年内",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 184);
								picker.$emit("pick", [start, end]);
							},
						},
						{
							text: "一年内",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
								picker.$emit("pick", [start, end]);
							},
						},
					],
				},
			}
		},
		methods:{
			
		}
	}
</script>

<style>
</style>
