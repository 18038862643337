<template>
  <div>
    <div class="work-title bg-white flex justify-content-center">
      <el-button
        round
        @click="colorChange(3)"
        :class="color3 ? 'color-box' : ''"
        >按班级</el-button
      >
      <el-button
        :class="color4 ? 'color-box' : ''"
        round
        @click="colorChange(4)"
        >按学员</el-button
      >
    </div>
    <div v-show="color3">
      <ByClass></ByClass>
    </div>

    <div v-show="color4">
      <ByStudent></ByStudent>
    </div>
  </div>
</template>

<script>
import AsList from "@/components/as-list";
import ByClass from "./ByClass";
import ByStudent from "./ByStudent";
export default {
  components: {
    AsList,
    ByClass,
    ByStudent,
  },
  data() {
    return {
      color3: true,
      color4: false,
      isShow: false,
      classTime: "",
      schoolInput: "",
      date: "",
      stuInput: "",
      schoolList: [], //经办校区
      schoolManager: "", //学管师
      schoolManagerList: [],
      gender: "", //性别
      attention: "", //是否关注家校中心
      Arrears: "", //是否欠费
      orderSatus: ["待支付", "已完成"], // 订单状态

      enrollmentList: [
        {
          StudentName: "周杰",
          gender: "女",
          relationship: "母亲",
          phone: "13200001231",
          schoolManager: "何姝",
          attention: "",
          bind: "",
          humanFace: "",
          arrears: "10000",
          status: "在读",
          school: "驿都城校区",
        },
      ],
      list: [
        {
          label: "所属课程",
          value: "1",
        },
        {
          label: "实到/应该",
          value: "2",
        },
        {
          label: "助教",
          value: "3",
        },
        {
          label: "上课校区",
          value: "4",
        },
        {
          label: "科目",
          value: "5",
        },
      ],
    };
  },
  methods: {
    colorChange(type) {
      if (type == 3) {
        this.color3 = true;
        this.color4 = false;
      } else if (type == 4) {
        this.color4 = true;
        this.color3 = false;
      }
    },
    delOderStatus(index) {
      this.orderSatus.splice(index, index + 1);
    },
    handleClick(row) {},
  },
};
</script>

<style>
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}
</style>
