<template>
  <div class="total">
    <div class="configure">
      <div>
        <div>
          <span style="font-size: 20px">{{ week }}</span>
        </div>
        <div>
          <span style="font-size: 20px">{{ date_ }}</span>
        </div>
      </div>
      <!-- ClassToday -->
      <div
        class="work-title-all flex justify-content-between aligin-items-center flex-wrap-wrap margin-tb-sm"
      >
        <div
          class="work-title-content flex aligin-items-center flex-wrap-wrap"
          style="width: 60%"
        >
          <el-popover width="150" trigger="click">
            <el-checkbox-group @change="school_change" v-model="school_name">
              <el-checkbox
                class="padding-xs"
                :label="item.name"
                v-for="(item, index) of school_list"
                :key="index"
              ></el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <span>校区</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
          <div v-if="!query.school_id.length" slot="reference">
            <i class="el-icon-link text-grey" />
            <span class="text-grey">教室</span>
            <i class="el-icon-caret-bottom text-grey" />
          </div>

          <el-popover v-else width="200" trigger="click">
            <el-checkbox-group
              @change="Classroom_change"
              v-model="Classroom_name"
            >
              <el-checkbox
                class="padding-xs"
                :label="item.name"
                v-for="(item, index) of Classroom_list"
                :key="index"
              />
            </el-checkbox-group> 
            <div slot="reference" class="cursor">
              <i class="el-icon-link text-grey" />
              <span>教室</span>
              <i class="el-icon-caret-bottom" />
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <el-radio-group @change="is_onetoone_change" v-model="is_onetoone">
              <el-radio class="padding-xs" label="全部" />
              <el-radio class="padding-xs" label="班课" />
              <el-radio class="padding-xs" label="一对一" />
            </el-radio-group>
            <div slot="reference" class="cursor">
              <span>授课模式</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <el-checkbox-group @change="Subject_change" v-model="Subject_name">
              <el-checkbox
                class="padding-xs"
                :label="item.title"
                v-for="(item, index) of Subject_Type"
                :key="index"
              />
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <span>科目</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
          <el-popover width="200" trigger="click">
            <el-checkbox-group @change="teacher_change" v-model="teacher_name">
              <el-checkbox
                class="padding-xs"
                :label="item.full_name"
                v-for="(item, index) of ArrangeTeachers_List"
                :key="index"
              />
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <span>上课教师</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
          <el-popover width="150" trigger="click">
            <el-checkbox-group
              @change="curriculum_change"
              v-model="curriculumname"
            >
              <el-checkbox
                v-for="(item, index) in curriculumstate"
                :key="index"
                class="padding-xs"
                :label="item.name"
              />
            </el-checkbox-group>
            <div slot="reference" class="cursor">
              <span>记上课状态</span>
              <i class="el-icon-caret-bottom" />
              <span class="border-right" />
            </div>
          </el-popover>
        </div>
        <div
          style="width: 40%"
          class="work-title-search flex justify-content-flexEnd"
        >
          <el-input
            placeholder="请输入班级/一对一名称"
            v-model="KeyWord"
            class="input-with-select"
            size="mini"
            style="width: 60%"
          >
            <el-button
              @click="select_Student"
              slot="append"
              icon="el-icon-search"
            />
          </el-input>

          <span class="text-blue margin-left-sm cursor" @click="del_selsect"
            >清空筛选</span
          >
        </div>
      </div>
      <div
        class="work-title-status flex aligin-items-center justify-content-between flex-wrap-wrap margin-top-sm"
      >
        <div class="flex aligin-items-center flex-wrap-wrap">
          <div
            v-if="school_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>校区：</div>
            <div
              v-for="(btn, btnIndex) in school_name"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'school_name')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="Classroom_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>教室：</div>
            <div
              v-for="(btn, btnIndex) in Classroom_name"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'Classroom_name')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="Subject_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>科目：</div>
            <div
              v-for="(btn, btnIndex) in Subject_name"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'Subject_name')"
                />
              </el-button>
            </div>
          </div>
          <!-- <div
            v-if="School_admin.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>学管师：</div>
            <div
              v-for="(btn, btnIndex) in School_admin"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i class="el-icon-close" @click="del_School_admin(btnIndex)" />
              </el-button>
            </div>
          </div> -->
          <div
            v-if="teacher_name.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>上课教师：</div>
            <div
              v-for="(btn, btnIndex) in teacher_name"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'teacher_name')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="curriculumname.length"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>记上课状态：</div>
            <div
              v-for="(btn, btnIndex) in curriculumname"
              :key="btnIndex"
              :class="{ 'margin-left': btnIndex > 0 }"
            >
              <el-button size="mini">
                {{ btn }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus(btnIndex, 'curriculumname')"
                />
              </el-button>
            </div>
          </div>
          <div
            v-if="is_onetoone != '全部'"
            class="text-gray flex aligin-items-center margin-left-lg margin-top-sm"
          >
            <div>授课模式：</div>
            <div>
              <el-button size="mini">
                {{ is_onetoone }}
                <i
                  class="el-icon-close"
                  @click="del_Choose_Campus('btnIndex', 'is_onetoone')"
                />
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-between margin-top">
        <div class="flex flex-wrap-wrap" slot="reference">
          <el-button type="primary" size="mini" @click="ClassOneToOne"
            >未排课记上课</el-button
          >
        </div>
        <el-popover width="200" trigger="click">
          <el-checkbox-group
            v-model="Groupingdata"
            v-for="(item, index) in Groupinglist"
            :key="index"
          >
            <el-checkbox class="padding-xs" :label="item.value">{{
              item.label
            }}</el-checkbox>
          </el-checkbox-group>
          <div slot="reference">
            <el-button type="primary" size="mini">自定义显示列</el-button>
          </div>
        </el-popover>
      </div>
      <div class="myClient-text flex justify-content-between myClient-top-10">
        <div>共计{{ rosterSize }}条记录</div>
      </div>
      <div style="margin-top: 10px">
        <!-- ArrangeCurriculum_List getArrangeCurriculumList-->
        <as-list
          :ajaxMethods="{ list: 'queryTimeList' }"
          :listQueryProp="query"
          @requestChange="requestChange"
        >
          <el-table-column
            align="center"
            sortable
            prop="attend_class_timeinterval_start"
            label="上课时段"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.class_begin_time_start">
                {{ scope.row.class_begin_time_start.split("T")[0] }}</span
              >
              -<span v-if="scope.row.class_begin_time_end">
                {{ scope.row.class_begin_time_end.split("T")[0] }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="arrange_classes_name"
            label="班级/一对一"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="curriculum.curriculum_name"
            label="所属课程"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="arrange_teacher_name"
            label="上课教师"
          >
          </el-table-column>
          <el-table-column align="center" prop="status_name" label="状态">
            <template slot-scope="scope">
              <span class="cursor"> {{ scope.row.status_name }}</span>
            </template>
          </el-table-column>
          <template v-for="(item, index) in columnslistComputed">
            <el-table-column
              v-if="['used_hours', 'over_hours'].includes(item.value)"
              :key="index"
              sortable
              height="40"
              :prop="item.value"
              :label="item.label"
            >
            </el-table-column>
            <el-table-column
              v-else
              :key="index"
              height="40"
              :prop="item.value"
              :label="item.label"
            >
            </el-table-column>
          </template>
          <el-table-column
            align="center"
            prop="class_room_name"
            label="教室"
          ></el-table-column>
          <el-table-column align="center " label="操作">
            <template slot-scope="scope">
              <span class="text-blue cursor" @click="gopage(scope.row)"
                >记上课</span
              >
            </template>
          </el-table-column>
        </as-list>
      </div>
    </div>
  </div>
</template>
<script>
import AsList from "@/components/as-list";
import Asfilter from "@/components/as-filter";
import { course, starList, withWithout } from "@/config/index";
import Administration from "./Administration.vue";
import classRecord from "./classRecord.vue";
import summaryClassHours from "./summaryClassHours.vue";
import teacherAudition from "./teacherAudition.vue";
export default {
  components: {
    AsList,
    Asfilter,
    classRecord,
    Administration,
    summaryClassHours,
    teacherAudition,
  },
  data() {
    return {
      KeyWord: "",
      Subject_name: [],
      Subject_Type: [],
      SubjectQuery: {
        module: "SubjectType",
        parentId: 10,
      },
      rosterSize: 0, //共计多少条
      School_admin: [],
      School_admin1: "",
      School_admin_id: [],
      ArrangeTeachers_List: [], //教师
      is_onetoone: "全部", //授课模式
      school_name1: "",
      school_name: [], //学校显示
      school_list: [], //学校列表
      week: "",
      date_: "",
      status_: ["未知", "未上课", "已上课"],
      Classroom_list: [], //教室
      Classroom_name: [], //教室名称
      curriculumname: [],
      curriculumstate: [
        { name: "未知", id: 0 },
        { name: "未上课", id: 1 },
        { name: "记上课", id: 2 },
      ],
      teacherQueryData: {
        post_type: [8],
        KeyWord: "",
        PageSize: 20,
        PageIndex: 1,
      },
      // query: {
      //   subject_id: [], //科目
      //   school_admin: [],
      //   school_id: [],
      //   Curriculum_Type: [],
      //   curriculum_id: [],
      //   division_time: "",
      //   status: [],
      //   student_name: "",
      //   phone: "",
      //   class_name: "",
      //   teacher_name: "",
      //   KeyWord: "",
      //   PageIndex: 1,
      //   PageSize: 10,
      // },
      query: {
        //time_slot: "", //时间段 必填 ~ 隔开 eg: 2020-12-01~2021-01-01
        // class_begin_date: "", //上课时间 ,
        school_id: [], //校区id
        // curriculumntype: [], //课程类别 （数据字典） ,
        teacher_id: [], //教师id ,
        roomid: [], //教室id
        // curriculumn_id: [], //课程id
        subject_id: [], //科目id(数据字典) ,
        status: [], //排课状态（是否记上课) ,
        today: true, //是否只获取今天的排课 ,
        is_onetoone: null, // 一对一排课或班课 默认 需 false 班课 ,
        // student_id: "", //查询某个学员的排课信息 ,
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      },
      editType: 1,
      isShow: false,
      schoolInput: "",
      date: "",
      stuInput: "",
      Campus: "", //上课校区
      schoolManager: "", //学管师
      teacher_name: [], //上课教师
      gender: "", //性别
      attention: "", //是否关注家校中心
      Arrears: "", //是否欠费
      orderSatus: ["待支付", "已完成"], // 订单状态
      activeName: "two",
      datalist: [],
      Groupingdata: [],
      Groupinglist: [
        {
          label: "所属课程",
          value: "curriculum.curriculum_name",
        },
        {
          label: "实到/应到",
          value: "2",
        },
        {
          label: "分班日期",
          value: "1",
        },
        {
          label: "助教",
          value: "arrange_assistant_name",
        },
        {
          label: "上课校区",
          value: "school_name",
        },
        {
          label: "科目",
          value: "arrange_subject_name",
        },
      ],
    };
  },

  computed: {
    columnslistComputed() {
      return this.Groupinglist.filter(
        (t) => this.Groupingdata.findIndex((t1) => t1 == t.value) > -1
      );
    },
  },

  created() {
    this.getdate();
  },
  methods: {
    select_Student() {
      this.query.KeyWord = this.KeyWord;
    },
    //as-list接口请求返回
    requestChange(res) {
      this.rosterSize = res.total;
      // if (res.outer_data) {
      //   this.arrearMoney = res.outer_data.arrearMoney;
      // }
    },
    curriculum_change() {
      var curriculumstate = this.curriculumstate.filter((rs) => {
        return this.curriculumname.indexOf(rs.name) != -1;
      });
      this.query.status = curriculumstate.map((rs) => {
        return rs.id;
      });
    },
    Classroom_change() {
      var Classroom_name = this.Classroom_list.filter((rs) => {
        return this.Classroom_name.indexOf(rs.name) != -1;
      });
      this.query.roomid = Classroom_name.map((rs) => {
        return rs.id;
      });
    },
    teacher_change() {
      var teacher_name = this.ArrangeTeachers_List.filter((rs) => {
        return this.teacher_name.indexOf(rs.full_name) != -1;
      });
      this.query.teacher_id = teacher_name.map((rs) => {
        return rs.id;
      });
    },
    //清空筛选
    del_selsect() {
      this.KeyWord = "";
      this.is_onetoone = "全部";
      this.Subject_name = [];
      this.curriculumname = [];
      this.school_name = [];
      this.Classroom_name = [];
      this.teacher_name = [];
      this.query = {
        school_id: [], //校区id
        // curriculumntype: [], //课程类别 （数据字典） ,
        teacher_id: [], //教师id ,
        roomid: [], //教室id
        // curriculumn_id: [], //课程id
        subject_id: [], //科目id(数据字典) ,
        status: [], //排课状态（是否记上课) ,
        today: true, //是否只获取今天的排课 ,
        is_onetoone: null, // 一对一排课或班课 默认 需 false 班课 ,
        // student_id: "", //查询某个学员的排课信息 ,
        KeyWord: "",
        PageIndex: 1,
        PageSize: 10,
      };
    },
    //授课模式
    is_onetoone_change() {
      if (this.is_onetoone == "全部") {
        this.query.is_onetoone = null;
      } else if (this.is_onetoone == "一对一") {
        this.query.is_onetoone = true;
      } else {
        this.query.is_onetoone = false;
      }
    },
    gopage(data) {
      var _this = this;
       if (data.id) {
        // console.log(_this.$route);
        // this.dialogVisible = false;
        _this.$router.push({
          path: "/educationCenter/addRemembertoClass",
          query: { classid: data.arrange_classes, id: data.id, type: "remember" },
        });
      } 
      // if (data.arrange_classes) {
      //   // console.log(_this.$route);
      //   // this.dialogVisible = false;
      //   _this.$router.push({
      //     path: "/educationCenter/addRemembertoClass",
      //     query: { id: data.arrange_classes },
      //   });
      // } else {
      //   this.$message.error("请先点击一个信息在试试");
      // }
    },
    //科目
    Subject_change() {
      var school_id = this.Subject_Type.filter((rs) => {
        return this.Subject_name.indexOf(rs.title) != -1;
      });
      this.query.subject_id = school_id.map((rs) => {
        return rs.id;
      });
    },
    //删除学管师
    del_School_admin(index) {
      this.School_admin.splice(index, 1);
      this.School_admin_id.splice(index, 1);
      this.query.school_admin = this.School_admin_id;
    },
    getIntroducer(keyword) {
      var model = {
        post_tag:['学管'],
        PageIndex: this.getIntroducer_PageIndex,
        PageSize: this.getIntroducer_PageSize,
        KeyWord: keyword,
      };
      return this.API.accountQuery(model);
    },

    //搜索事件
    querySearch(queryString, cb) {
      this.getIntroducer_PageIndex = 1;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;

        cb(res.data.rows || []);
      });
    },
    loadMoreStudent(queryString, cb) {
      this.getIntroducer_PageIndex++;
      this.getIntroducer(queryString).then((res) => {
        let list = res.data.rows;

        cb(list);
      });
    },
    //点击某一项的事件 学管师
    selectChange(item) {
      var _true = false;
      if (this.School_admin_id && this.School_admin_id.length) {
        for (let index = 0; index < this.School_admin_id.length; index++) {
          if (this.School_admin_id[index] == item.id) {
            return (_true = true);
          } else {
            _true = false;
          }
        }
        if (!_true) {
          this.School_admin.push(item.full_name);
          this.School_admin_id.push(item.id);
          this.query.school_admin = this.School_admin_id;
        }
      } else {
        this.School_admin.push(item.full_name);
        this.School_admin_id.push(item.id);
        this.query.school_admin = this.School_admin_id;
      }
    },
    //删除校区搜索
    del_Choose_Campus(index, del) {
      if (del == "school_name") {
        //清除校区
        this.school_name.splice(index, 1);
        var school_id = this.school_list.filter((rs) => {
          return this.school_name.indexOf(rs.name) != -1;
        });
        this.query.school_id = school_id.map((rs) => {
          return rs.id;
        });

        this.getClassroom();
        // this.query.school_id = [];
      } else if (del == "Subject_name") {
        //清除科目
        this.Subject_name.splice(index, 1);
        var subject_id = this.Subject_Type.filter((rs) => {
          return this.Subject_name.indexOf(rs.title) != -1;
        });
        this.query.subject_id = subject_id.map((rs) => {
          return rs.id;
        });
      } else if (del == "is_onetoone") {
        this.is_onetoone = "全部";
        this.query.is_onetoone = null;
      } else if (del == "teacher_name") {
        this.teacher_name.splice(index, 1);
        this.teacher_change();
      } else if (del == "Classroom_name") {
        this.Classroom_name.splice(index, 1);
        this.Classroom_change();
      } else if (del == "curriculumname") {
        this.curriculumname.splice(index, 1);
        this.curriculum_change();
      }
    },
    getClassroom() {
      if (this.query.school_id.length) {
        //查询教室
        var Classroom = {
          shoolid: this.query.school_id[0],
          KeyWord: "",
          PageIndex: 1,
          PageSize: 10000,
        };
        this.API.getClassroomList(Classroom).then((res) => {
          this.Classroom_list = res.data.rows || [];
        });
      }
    },
    get_NeedInformation() {
      //查询校区
      var model = {
        type: 1,
        PageIndex: 1,
        PageSize: 10000,
     
      };
      this.API.OrganizationList(model).then((res) => {
        this.school_list = res.data.rows;
      });
      this.API.SystemGetSettings("OrderCurriculumnsStatus", false).then(
        (res) => {
          this.Enrollment_Status = res || [];
        }
      );
      this.$Dictionary.GetDictionaryTree(this.SubjectQuery).then((res) => {
        this.Subject_Type = res.data || [];
      });
      // //查询教师
      // var model_teachers = {
      //   AccountType: [4],
      //   KeyWord: "",
      //   PageSize: 2000,
      //   PageIndex: 1,
      // };
      // this.API.accountQuery(model_teachers).then((res) => {
      //   this.ArrangeTeachers_List = res.data.rows;
      // });

      // 获取教师列表
var ArrangeTeachers = {
        // AccountType: [5],
        post_tag:['教师'],
        PageIndex: 1,
        PageSize: 100,
      }; 
      this.API.accountQuery(ArrangeTeachers).then((res) => {
        this.ArrangeTeachers_List = res.data.rows;
      });
    
      // this.API.accountQuery(this.teacherQueryData).then((res) => {
      //   this.ArrangeTeachers_List = res.data.rows;
      // });
    },
    //校区
    school_change(e) {
      var school_id = this.school_list.filter((rs) => {
        return this.school_name.indexOf(rs.name) != -1;
      });
      this.query.school_id = school_id.map((rs) => {
        return rs.id;
      });
      this.getClassroom();
    },
    getdate() {
      var date = new Date();
      this.date_ = date.toLocaleDateString();
      var day = date.getDay();
      var weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      this.week = weeks[day];
      this.school_change();
      this.get_NeedInformation();
    },

    delOderStatus(index) {
      this.orderSatus.splice(index, index + 1);
    },
    openDate(t) {
      if (t) {
        this.$refs.evaluation.pickerVisible = true;
      } else {
        this.$refs.datePicker.pickerVisible = true;
      }
    },

    ClassOneToOne() {
      this.$Dialog.ClassOneToOne().then((res) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}

.total {
  width: 100%;
}

.shool {
  margin: 0px 10px;
}

.configure {
  width: 100%;
  padding: 10px;
}

.configure {
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

.el-input__icon {
  line-height: 40px;
}

.el-select {
  display: block;
}
</style>
