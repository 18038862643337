<template>
	<div class="total">
		<div class="configure">
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="记上课" name="two">
					<div class="work-title bg-white flex justify-content-center">
						<el-button round @click="colorChange(1)" :class="color1 ? 'color-box' : ''">今日上课</el-button>
						<el-button :class="color2 ? 'color-box' : ''" round @click="colorChange(2)">补记上课</el-button>
					</div>
					<div v-show="color1">
						<ClassToday></ClassToday>
					</div>
					<div v-show="color2">
						<SupplementaryLessons></SupplementaryLessons>
					</div>
				</el-tab-pane>
				<el-tab-pane label="上课记录" name="three">
					<classRecord></classRecord>
				</el-tab-pane>
				<el-tab-pane label="补课管理" name="fore">
					<Administration></Administration>

				</el-tab-pane>

				<!-- <el-tab-pane label="课时汇总" name="fives">
					<summaryClassHours></summaryClassHours>
				</el-tab-pane> -->
				<!-- <el-tab-pane label="教师试听" name="six">
					<teacherAudition></teacherAudition>
				</el-tab-pane> -->
			</el-tabs>
		</div>
	</div>
</template>
<script>
	import AsList from "@/components/as-list";
	import Asfilter from "@/components/as-filter";
	import {
		course,
		starList,
		withWithout
	} from "@/config/index";

import SupplementaryLessons from "./components/SupplementaryLessons.vue";
import ClassToday from "./components/ClassToday.vue";
import Administration from "./components/Administration.vue";
import classRecord from "./components/classRecord.vue";
import summaryClassHours from "./components/summaryClassHours.vue";
import teacherAudition from "./components/teacherAudition.vue";
export default {
  components: {
    AsList,
    Asfilter,
    classRecord,
    Administration,
    summaryClassHours,
    teacherAudition,
    ClassToday,
    SupplementaryLessons,
  },
  data() {
    return {
      activeName: "two",
      color1: true,
      color2: false,
    };
  },
  methods: {
    handleClick(row) {},

			colorChange(type) {
				if (type == 1) {
					this.color1 = true;
					this.color2 = false;
				} else if (type == 2) {
					this.color2 = true;
					this.color1 = false;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.color-box {
		color: #0081ff;
		border: 1px solid #8cc5ff;
		background-color: #e8f5ff;
	}

	.total {
		width: 100%;
	}

	.shool {
		margin: 0px 10px;
	}

	.configure {
		width: 100%;
		padding: 10px;
	}

	.configure {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.el-input__icon {
		line-height: 40px;
	}

	.el-select {
		display: block;
	}
</style>
